<template>
  <app-page>
    <div class="loading-screen" v-if="isLoading">
      <loader></loader>
    </div>

    <div class="merchant-page-spacer-current-order" v-if="user && user.currentOrder && merchant.slug === user.currentOrder.merchantSlug && this.$route.name === 'merchant'"></div>
    <div v-else class="merchant-page-spacer"></div>

    <div v-if="!isLoading && !merchantError && merchant" class="merchant-page">
      <app-nav></app-nav>
      <app-current-order></app-current-order>
      <app-header></app-header>

      <div class="container mt-5" v-if="!cart.deliveryAvailable && cart.deliveryClosedReason && merchant.shifts.open">
        <app-delivery-unavailable-reason></app-delivery-unavailable-reason>
      </div>
      <app-category-container-mono-cat v-if="merchant.menuStyle === 'mono_category' || merchant.menuStyle === 'grid_category'">
        <template #top>
          <div>
            <app-specialties :menu="menu"></app-specialties>
          </div>
        </template>
        <template #main>
          <app-product-section-mono-cat
            v-if="merchant.menuStyle === 'mono_category'"
            v-for="(category,index) in menu"
            v-bind:key="category.id"
            :category="category"
            :style="{ 'margin-bottom': index === menu.length - 1 ? '120px' : '0' }">
          </app-product-section-mono-cat>
          <app-product-section-grid-cat
            v-if="merchant.menuStyle === 'grid_category'"
            v-for="(category,index) in menu"
            v-bind:key="category.id"
            :category="category"
            :style="{ 'margin-bottom': index === menu.length - 1 ? '120px' : '0' }">
          </app-product-section-grid-cat>
        </template>
      </app-category-container-mono-cat>
      <app-category-container v-else>
        <template #top>
          <div>
            <app-specialties :menu="menu"></app-specialties>
          </div>
        </template>
        <template #main>
          <app-product-section v-for="category in menu" v-bind:key="category.id" :category="category"></app-product-section>
        </template>
      </app-category-container>
      <app-addresses></app-addresses>
      <app-platform-fee-info></app-platform-fee-info>
      <app-delivery-info></app-delivery-info>
      <app-map></app-map>
      <app-hours></app-hours>
      <app-addresse-editor></app-addresse-editor>
      <app-order-time v-if="merchant.shifts"></app-order-time>
      <app-product-exclusive-reactive></app-product-exclusive-reactive>
      <app-product-exclusive-proactive></app-product-exclusive-proactive>
      <app-product-change-date></app-product-change-date>
      <app-footer></app-footer>
    </div>
  </app-page>
</template>


<script>
import Nav from '../desktopComponents/Nav'
import Header from '../desktopComponents/Merchant/Header'
import OrderConfig from '../desktopComponents/Merchant/OrderConfig'
import ProductCarousel from '../desktopComponents/Products/ProductCarousel'
import ProductSection from '../desktopComponents/Products/ProductSection'
import ProductSectionMonoCat from '../desktopComponents/Products/ProductSectionMonoCat'
import ProductSectionGridCat from '../desktopComponents/Products/ProductSectionGridCat'
import Specialties from '../desktopComponents/Products/Specialties'
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'
import CartFloatingButton from "@/desktopComponents/Merchant/CartFloatingButton";
import Auth from "@/desktopComponents/User/Auth";
import OnboardingAccount from "@/desktopComponents/User/OnboardingAccount";
import Login from "@/desktopComponents/User/Login";
import Addresses from "@/desktopComponents/Merchant/Addresses";
import AddressEditor from "@/desktopComponents/Merchant/AddressEditor";
import CartComment from "@/desktopComponents/Merchant/CartComment";
import Map from "@/desktopComponents/Merchant/Map";
import DeliveryInfo from "@/desktopComponents/Merchant/DeliveryInfo";
import PlatformFeeInfo from "@/desktopComponents/Merchant/PlatformFeeInfo";
import Hours from "@/desktopComponents/Merchant/Hours";
import Register from "@/desktopComponents/User/Register";
import CategoryContainer from "@/desktopComponents/Products/CategoryContainer";
import CategoryContainerMonoCat from "@/desktopComponents/Products/CategoryContainerMonoCat";
import CurrentOrder from "@/desktopComponents/Merchant/CurrentOrder";
import Footer from "@/desktopComponents/Merchant/Footer";
import OrderTime from "@/desktopComponents/Merchant/OrderTime";
import ExclusiveReactive from "@/desktopComponents/Products/ExclusiveReactive";
import ExclusiveProactive from "@/desktopComponents/Products/ExclusiveProactive";
import ChangeDateCart from "@/desktopComponents/Products/ChangeDateCart";
import DeliveryUnavailableReason from "@/desktopComponents/Merchant/DeliveryUnavailableReason";
import Loader from "@/components/Loader";
import '@/scss/app_desktop.scss'
import AddProduct from "@/components/Modals/Product/AddProduct.vue";
import {$vfm} from "vue-final-modal";

export default {
  components: {
    'loader': Loader,
    'app-nav': Nav,
    'app-auth': Auth,
    'app-login': Login,
    'app-addresses': Addresses,
    'app-addresse-editor': AddressEditor,
    'app-onboarding-account': OnboardingAccount,
    'app-header': Header,
    'app-order-config': OrderConfig,
    'app-product-carousel': ProductCarousel,
    'app-product-section': ProductSection,
    'app-product-section-mono-cat': ProductSectionMonoCat,
    'app-product-section-grid-cat': ProductSectionGridCat,
    'app-specialties': Specialties,
    'app-cart-floating-button': CartFloatingButton,
    'app-cart-comment': CartComment,
    'app-map': Map,
    'app-delivery-info': DeliveryInfo,
    'app-platform-fee-info': PlatformFeeInfo,
    'app-hours': Hours,
    'app-register': Register,
    'app-category-container': CategoryContainer,
    'app-category-container-mono-cat': CategoryContainerMonoCat,
    'app-current-order': CurrentOrder,
    'app-footer': Footer,
    'app-order-time': OrderTime,
    'app-product-exclusive-reactive': ExclusiveReactive,
    'app-product-exclusive-proactive': ExclusiveProactive,
    'app-product-change-date': ChangeDateCart,
    'app-delivery-unavailable-reason': DeliveryUnavailableReason
  },
  metaInfo(){
    let title = !this.isLoading && this.merchant.metadata ? this.merchant.metadata.title : null;
    let description = !this.isLoading && this.merchant.metadata ? this.merchant.metadata.description : null;
    let image = !this.isLoading && this.merchant.metadata ? this.merchant.metadata.image.path : null;

    if(!this.isLoading){
      return {
        title: title,
        meta: [
          { name: 'description', content: description },
          { property: 'og:title', content: title },
          { property: 'og:description', content: description },
          { property: 'og:image', content: image },
          { property: 'og:url', content: location.href },
          { property: 'twitter:url', content: location.href },
          { property: 'twitter:title', content: title },
          { property: 'twitter:description', content: description },
          { property: 'twitter:image', content: image },
        ]
      }
    }
  },
  methods:{
    ...mapActions(['getMerchant', 'sendStat', 'createOrderToken', 'loadOrder', 'getAccessToken', 'getUser', 'getNearestAddress', 'setOrderType', 'checkLogin', 'backButton', 'setOrderTracking']),
    updateFavicon(){
      if(this.merchant && this.merchant.metadata){
        document.getElementById('ico').href = this.merchant.metadata.icon;
        document.getElementById('i180').href = this.merchant.metadata.icon180x180;
        document.getElementById('i32').href = this.merchant.metadata.icon32x32;
        document.getElementById('i16').href = this.merchant.metadata.icon16x16;
      }
    }
  },
  computed: {
    ...mapGetters(['menu', 'merchant', 'userToken', 'user', 'cart', 'merchantError', 'modal', 'products']),
    isLoading(){
      return !(this.menu !== null && this.merchant !== null && this.user !== null && this.cart !== null);
    }
  },
  async mounted() {
    window.scrollTo(0, 0);
    this.checkLogin();



    const slug = this.$route.params.merchantSlug ? this.$route.params.merchantSlug : location.host;


    await this.getMerchant({slug: slug})
        .catch((e) => {
          console.log('❌ Merchant loading error, redirect to home', slug);
        });

    // If merchant has error, stop loading...
    if(this.merchantError){
      this.$router.push('/');
      return;
    }
    console.log('👍 Merchant '+slug+' loaded succesfully');


    this.updateFavicon();
    this.getUser().catch(e => console.log('👤 No user logged in'));

    let source = 'direct';
    if(this.$route.query.source === 'marketplace'){
      source = 'marketplace';
    }
    await this.loadOrder({slug: this.merchant.slug, type: this.$route.query.type, source: source})
        .catch(() => {
          console.log('❌ Cart error with slug "'+slug+'", reload in 3s')
          setTimeout(() => {
            location.reload();
          },3000)
        });

    // open product from url query
    if(this.$route.query.product_id){
      const product = this.products.find(e => parseInt(e.id) === parseInt(this.$route.query.product_id));
      if(product){
        $vfm.show({component: AddProduct, bind: {product: JSON.parse(JSON.stringify(product))}});
        window.history.pushState({}, "",location.pathname + '#product');
      }
    }

    this.$router.beforeEach((to, from, next) => {
      this.backButton({from:from.hash.replace('#', ''), to:to.hash.replace('#', '')});
      next();
    });


    this.sendStat({name: 'desktop-merchant-page'});
  },
  watch: {
    isLoading(val){
      if(val === false){
        setTimeout(() => {

          if(this.$route.query.cart){
            this.$root.$emit('openCart');
            window.history.pushState('',document.title, location.origin + location.pathname);
          }

          if(this.$route.query.t){
            this.setOrderTracking(this.$route.query.t);
          }

          if(this.$route.query.stripe_error){
            if(this.cart.OrderProducts.length === 0 || this.cart.user === null){
              // Create announcement for error
              if(this.$te('stripe_errors.'+this.$route.query.stripe_error)){

                this.$store.commit('ADD_ANNOUNCEMENT', {
                  type: 'error',
                  body: this.$t('stripe_errors.'+this.$route.query.stripe_error),
                  position: 'top_product'
                });
              }
            }
            else{
              this.$router.push({name:'summary', params: {merchantSlug: this.$route.params.merchantSlug}, query: {stripe_error: this.$route.query.stripe_error}})
            }
          }

          if(this.$route.query.ref && this.$route.query.ref === 'merchant_on_site'){ // Set onsite
            this.setOrderType('on_site');
          }

          let hash = this.$route.hash.replace('#', '');
          if(hash && (hash !== 'openProduct' && hash !== 'openProductEditor' && hash !== 'openExclusiveProactive' && hash !== 'openExclusiveReactive'&& hash !== 'openChangeDateCart')){
            this.$root.$emit(hash);
          }

        },100)
        this.getNearestAddress();
      }
    },
    userToken(){
      if(this.$route.query.authed){
        this.getAccessToken();
      }
    },
    modal(val){
      if(val.length === 0){
        document.body.style.overflowY = "auto";
        document.body.style.position = "relative";
      }
      else {
        document.body.style.overflowY = "hidden";
        document.body.style.position = "relative";
      }
    }
  }
}
</script>
